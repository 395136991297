
              /** @type {import('tailwindcss').Config} */
              module.exports = {
                content: [
                  './pages/**/*.{js,ts,jsx,tsx,mdx}',
                  './components/**/*.{js,ts,jsx,tsx,mdx}',
                  './app/**/*.{js,ts,jsx,tsx,mdx}',
                ],
                theme: {
                  extend: {
                    backgroundImage: {
                      'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
                      'gradient-conic':
                        'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
                    },
                    colors: {
                      'maincolor': '#21346C',
                      'maincolortrans': '#2331424d',
                      'secondary': '#05294d',
                      'third': '#C62121',
                      'forth': '#e3e3e3',
                      'light': 'white',
                      'mainhover': 'gray'

                    },
                    backgroundColor: ['hover'],
                  },
                },
                plugins: [],
              }